import React from 'react';

import onderhoudImage1 from 'assets/images/pages/diensten_webshoponderhoud/1.jpg';
import onderhoudImage2 from 'assets/images/pages/diensten_webshoponderhoud/2.jpg';

import Title from 'blocks/Title';
import Image from 'blocks/Image';
import ContactCta from 'blocks/ContactCta';

import Layout from 'components/Layout';
import Seo from 'components/Seo';
import Section from 'components/Section';
import Heading from 'components/Heading';
import Text from 'components/Text';
import Button from 'components/Button';

const DienstenOnderhoud = () => (
  <Layout>
    <Seo image={{ src: onderhoudImage1 }} />

    <Title
      image={{ src: onderhoudImage1 }}
      alt="Webshop onderhoud door Batao"
      subtitle="Uw webshop, onze commitment"
      title="Webshop onderhoud door Batao"
    >
      Wil je een nieuwe webshop bouwen of je bestaande webshop verbeteren? Dan
      ben je bij Batao aan het juiste adres! Wij hebben de kennis en ervaring om
      jou alle zorgen rondom het webshop bouwen uit handen te nemen.
    </Title>

    <Section>
      <Image
        image={{ src: onderhoudImage2 }}
        alt="Jouw webshop prestaties houden wij goed in de gaten"
      >
        <Heading size="xl" as="h2">
          Jouw webshop prestaties houden wij goed in de gaten
        </Heading>

        <Text spacing="lg">
          Of het nu gaat om technische updates, hosting, (ontwerp) aanpassingen,
          het inbouwen van nieuwe, extra functionaliteit o f doorlopende
          optimalisatie, bij Batao houden we de prestaties van je webshop goed
          in de gaten, sturen we bij waar nodig en adviseren we over nieuwe
          kansen. Bekijk hieronder een aantal van onze diensten of neem contact
          op met onze projectmanager Janko om de mogelijkheden vrijblijvend te
          bespreken.
        </Text>

        <Button color="tertiary" to="/contact">
          Bespreek met ons de mogelijkheden
        </Button>
      </Image>
    </Section>

    <ContactCta />
  </Layout>
);

export default DienstenOnderhoud;
